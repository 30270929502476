import React from "react";

const baseURL = "https://www.eqnx.ch/ajax";

export const buildUrl = (params) => {
  let url = baseURL;
  for(let i = 0; i < 5; i++) {
    url = (params[i] === undefined) ? url + '/null' : url + '/' + params[i];
  }
  return prepareQuery(url);
}

export const prepareQuery = (query) => {
  return encodeURI(query);
}

export const encodeJsonForApiRest = (json) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(JSON.stringify(json));
  return btoa(String.fromCharCode.apply(null, data));
}