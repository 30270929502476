import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";

import { MdScreenSearchDesktop, MdManageSearch } from "react-icons/md";

import './Crawler.scss';

import { buildUrl } from '../tools/tool_urls';

export default function Crawler() {
  const [refreshLstUrls, setRefreshLstUrls] = useState(false);

  const [lstUrls, setLstUrls] = useState([]);
  const [noUrls, setNoUrls] = useState(false);

  const queryGetUrls = buildUrl(['sitemap', 'geturls']);
  const queryCrawlSitemap = buildUrl(['sitemap', 'crawl']);
  const queryIndexRecords = buildUrl(['sitemap', 'indexrecords']);

  useEffect(() => {
    const fetchData = async() => {
      await axios.get(queryGetUrls)
      .then((response) => {
        console.log({response});
        if(response.status === 200) {
          if(response.data.length === 0) {
            setNoUrls(true);
          }
          setLstUrls(response.data);
        }
      })
      .catch((err) => {
        console.log('ERROR : ', err);
      });
    }

    fetchData();
  }, [refreshLstUrls]);

  const crawlUrls = async() => {
    await axios.get(queryCrawlSitemap)
    .then((response) => {
      if(response.status === 200) {
        if(response.data === 1) {
          setRefreshLstUrls(true);
        }
      }
    })
    .catch((err) => {
      console.log('ERROR : ', err);
    });
  };

  const indexRecords = async() => {
    await axios.get(queryIndexRecords)
    .then((response) => {
      if(response.status === 200) {
        if(response.data === 1) {
          setRefreshLstUrls(true);
        }
      }
    })
    .catch((err) => {
      console.log('ERROR : ', err);
    });
  }

  return(
    <div className="module-wrapper">
      <div className="module-header">
        <div className="module-title">DocQuest Crawler</div>
        <div className="modulte-tools">
          <ul>
            <li>
              <div 
                className="tool-btn"
                onClick={crawlUrls}
                ><MdManageSearch/><span>Parsing des URLs</span></div>
            </li>
            <li>
              <div 
                className="tool-btn"
                onClick={indexRecords}
                ><MdScreenSearchDesktop/><span>Indexation</span></div>
            </li>
          </ul>
        </div>
      </div>
      <div className="module-body">
        <div className="urls-manager">
          <ul className="urls-lst-wrapper">
            <LstUrlsHeader/>
            {noUrls && (
              <li className="urls-lst-urls">
                <div>Aucune URLs enregistrées pour le moment...</div>
                <div>&nbsp;</div>
              </li>
            )}
            {!noUrls && (
              <>
                {lstUrls.map((url, index) => (
                  <li className="urls-lst-urls" key={url.i_url + '-'  + index}>
                    <div className="urls-url">{url.x_url}</div>
                    <div className="urls-last-update">{url.d_last_update}</div>
                    <div className="urls-content-details">Lorem</div>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

function LstUrlsHeader() {
  return(
    <li className="urls-lst-urls titles">
      <div className="urls-url">URL</div>
      <div className="urls-last-update">Dernière mise à jour</div>
    </li>
  );
}