import React from 'react';
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { MdSave } from "react-icons/md";

import { buildUrl, encodeJsonForApiRest } from '../tools/tool_urls';
import { removeAccents } from '../tools/tool_str';
import { buildPassword } from '../tools/tool_pwd';

export default function AccountImport(props) {
  const formRef = useRef(null);
  const [importedUsers, setImportedUsers] = useState([]);
  const [displayNewUsers, setDisplayNewUsers] = useState(false);
  const [importDone, setImportDone] = useState(true);

  const onSubmitHandler = (e) => {
    e.preventDefault();
  }

  const onChangeHandler = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");

    fileReader.onload = (e) => {
      try {
        const parsedData = JSON.parse(e.target.result);
        const updatedUsers = updateUserData(parsedData);
        setImportedUsers([...importedUsers, ...updatedUsers]);
      }
      catch(error) {
        console.error("Error parsing JSON: ", error);
      }
    };
  }

  const updateUserData = (users) => {
    const updatedUsers = users.map((user) => ({
      ...user,
      username: buildUserName(user.name, user.forname).toLowerCase(),
      password: buildPassword(11)
    }));

    return updatedUsers;
  }

  useEffect(() => {
    if(importedUsers.length > 0) {
      setDisplayNewUsers(true);
    }
  }, [importedUsers]);

  const buildUserName = (name, forname) => {
    return forname.substr(0, 1) + removeAccents(name);
  }

  const saveUsers = () => {
    importedUsers.forEach((user, index) => {
      const base64String = encodeJsonForApiRest(user);
      const createURL = buildUrl(['account', 'create-imported', base64String]);

      const sendData = async() => {
        await axios.post(createURL)
        .then((response) => {
          if(response.status === 200) {
            // console.log('Utilisateur créé...');
          }
          else {
            setImportDone(false);
          }
        })
        .catch((err) => {
          console.log('ERROR : ', err);
        });
      }
  
      sendData();
    });

    if(importDone) {
      // console.log("L'import des nouveaux comptes est terminé avec succès...");
      setImportedUsers([]);
      setDisplayNewUsers(false);
    }
  }

  return(
    <div className="account-import">
      <div className="title">Importer des comptes</div>
      <form ref={formRef} onSubmit={onSubmitHandler}>
        <div className="form-container">
          <div className="field-container">
            <label>Fichier JSON</label>
            <div className="field">
              <input 
                type="file"
                name="x_flie"
                id="account-json-file"
                placeholder="Nom"
                onChange={onChangeHandler}
              />
            </div>
            <div className='button'>
              <div 
                className="tool-btn" 
                onClick={saveUsers}
              ><MdSave /><span>Enregistrer</span></div>
            </div>
          </div>
        </div>
      </form>
      <div className="accounts-imports">
        <ul className="accounts-imports-ul">
          {!displayNewUsers && (
            <li className="account-import-li-msg">Importer les nouveaux utilisateurs à l'aide d'un fichier JSON</li>
          )}
          {displayNewUsers && (
            <>
              <AccountLineHeader />
              {importedUsers.map((user, index) => (
                <li className="account-import-li" key={user.email + '-'  + index}>
                  <div className="account-name">{user.name}</div>
                  <div className="account-forname">{user.forname}</div>
                  <div className="account-email">{user.email}</div>
                  <div className="account-organisation">{user.school}</div>
                  <div className="account-username">{user.username}</div>
                  <div className="account-password">{user.password}</div>
                </li>
              ))}
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

function AccountLineHeader() {
  return(
    <li className="account-import-li title">
      <div className="account-name">Nom</div>
      <div className="account-forname">Prénom</div>
      <div className="account-email">Email</div>
      <div className="account-organisation">Organisation</div>
      <div className="account-username">Utilisateur</div>
      <div className="account-password">Mot de passe</div>
    </li>
  );
}