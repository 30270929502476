import React from 'react';
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { buildUrl, encodeJsonForApiRest } from '../tools/tool_urls';

export default function AccountCreate() {
  const formRef = useRef(null);
  const [newUser, setNewUser] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [roles, setRoles] = useState([]);

  const queryOrganisations = buildUrl(['account', 'search', 'organisations']);
  const queryRoles = buildUrl(['account', 'search', 'roles']);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const base64String = encodeJsonForApiRest(newUser);
    const createURL = buildUrl(['account', 'create', base64String]);

    const sendData = async() => {
      await axios.post(createURL)
      .then((response) => {
        if(response.status === 200) {
          // console.log('Utilisateur créé...');
          setNewUser([]);
          formRef.current.reset();
        }
      })
      .catch((err) => {
        console.log('ERROR : ', err);
      });
    }

    sendData();
  }

  const onChangeHandler = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value
    });
  }

  useEffect(() => {
    const fetchData = async() => {
      const response = await axios.get(queryOrganisations).catch((err) => {
        console.log('ERROR : ', err);
      });

      if(response) {
        setOrganisations(response.data);
      }
    }

    fetchData()
      .catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async() => {
      const response = await axios.get(queryRoles).catch((err) => {
        console.log('ERROR : ', err);
      });

      if(response) {
        setRoles(response.data);
      }
    }

    fetchData()
      .catch(console.error);
  }, []);

  return(
    <div className="account-create">
      <div className="title">Créer un compte</div>
      <form ref={formRef} onSubmit={onSubmitHandler}>
        <div className="form-container">
          <div className="field-container">
            <label>Nom</label>
            <div className="field">
              <input 
                type="text"
                name="name"
                id="account-field-name"
                placeholder="Nom"
                value={newUser.nom}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>Prénom</label>
            <div className="field">
              <input 
                type="text"
                name="firstname"
                id="account-field-firstname"
                placeholder="Prénom"
                value={newUser.prenom}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>E-mail</label>
            <div className="field">
              <input 
                type="email" 
                name="email"
                id="account-field-email"
                placeholder="E-mail"
                value={newUser.email}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>Organisation</label>
            <div className="field">
            <select 
              name="organisation" 
              id="account-field-organisation"
              value={newUser.organisation}
              onChange={onChangeHandler}>
              <option value="null" defaultValue>Choisissez une organisation</option>
              {organisations.map((organisation, index) => (
                <option key={organisation.c_organisation + '-' + index} value={organisation.c_organisation}>{organisation.x_organisation}</option>
              ))}
            </select>
            </div>
          </div>
          <div className="field-container">
            <label>Nom d'utilisateur</label>
            <div className="field">
              <input 
                type="text" 
                name="username"
                id="account-field-username"
                placeholder="Nom d'utilisateur"
                value={newUser.username}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>Rôle</label>
            <div className="field scrollable">
              <ul className="account-fields-roles">
                {roles.map((role, index) => (
                  <li key={role.c_role + '-' + index} className="field-container checkbox">
                    <div className="field">
                      <input
                        type="checkbox"
                        name={"role-" + index}
                        value={role.c_role}
                        onChange={onChangeHandler}
                      />
                    </div>
                    <label>{'(' + role.c_role + ') ' + role.x_role}</label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="field-container span-2 content-right no-label">
            <label>&nbsp;</label>
            <div className="field">
              <input 
                type="submit" 
                value="Créer"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}