import React from 'react';
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { MdDeleteOutline, MdDone, MdClear, MdCreate, MdAdd, MdHowToReg, MdUploadFile } from "react-icons/md";

import './Accounts.scss';

import { buildUrl } from '../tools/tool_urls';
import { removeAccents } from '../tools/tool_str';
import { useDebounce } from "../../hooks/debounceHook";
import AccountCreate from './accounts_create';
import AccountEdit from './accounts_edit';
import AccountImport from './accounts_import';

export default function Accounts() {
  const inputRef = useRef();
  const [displayAccountCreate, setDisplayAccountCreate] = useState(false);
  const [displayAccountEdit, setDisplayAccountEdit] = useState(false);
  const [displayAccountListe, setDisplayAccountListe] = useState(true);
  const [displayAccountImport, setDisplayAccountImport] = useState(false);

  const [displayAllAccounts, setDisplayAllAccounts] = useState(true);
  const [displayFiltredAccounts, setDisplayFiltredAccounts] = useState(false);

  const [currentAccount, setCurrentAccount] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchedAccounts, setSearchedAccounts] = useState([]);
  const [noAccounts, setNoAccounts] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);

  const queryGetAccounts = buildUrl(['account', 'search', 'all']);

  const showAccountCreate = () => {
    setDisplayAccountCreate(true);
    setDisplayAccountEdit(false);
    setDisplayAccountListe(false);
    setDisplayAccountImport(false);
  }

  const showAccountEdit = (account) => {
    setCurrentAccount(account);
    setDisplayAccountCreate(false);
    setDisplayAccountEdit(true);
    setDisplayAccountListe(false);
    setDisplayAccountImport(false);
  }

  const showAccountListe = () => {
    setDisplayAccountCreate(false);
    setDisplayAccountEdit(false);
    setDisplayAccountListe(true);
    setDisplayAccountImport(false);
    setRefreshFlag(true);
  }

  const showAccountImport = () => {
    setDisplayAccountImport(true);
    setDisplayAccountCreate(false);
    setDisplayAccountEdit(false);
    setDisplayAccountListe(false);
  }

  useEffect(() => {
    const fetchData = async() => {
      await axios.get(queryGetAccounts)
      .then((response) => {
        if(response.data && response.data.length === 0) {
          setNoAccounts(true);
        }
        setAccounts(response.data);
      })
      .catch((err) => {
        console.log('ERROR : ', err);
      });
    }

    fetchData()
  }, [refreshFlag]);

  const userDelete = async(e) => {
    const URL = buildUrl(['account', 'delete', e.target.dataset.userid]);
    await axios.delete(URL)
    .then((response) => {
      if(response.status === 200) {
        // console.log('Suppression effectuée...');
        setRefreshFlag(true);
      }
    })
    .catch((err) => {
      console.log('ERROR : ', err);
    });
  }

  const testIsClient = (roles) => {
    let result = 'eqnx';

    roles.map((role) => {
      if(role.c_role === 'CLIENT') {
        result = 'client';
      }
    });
    
    return result;
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      setSearchedAccounts([]);
    }
  }

  const onSearchHandler = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);    
  }

  const searchForAccounts = () => {
    if(searchQuery.length < 3) {
      setSearchedAccounts([]);
    }
    else {
      let filtred = accounts.filter((account) => {
        if(removeAccents(account.x_nom).toLowerCase().indexOf(searchQuery) > -1 || removeAccents(account.x_prenom).toLowerCase().indexOf(searchQuery) > -1 || removeAccents(account.x_organisation).toLowerCase().indexOf(searchQuery) > -1) {
          return account;
        }
      });
      console.log({filtred});
      setSearchedAccounts([...searchedAccounts, ...filtred]);
    }
  }

  useEffect(() => {
    console.log({searchedAccounts});
    if(searchedAccounts.length > 0) {
      setDisplayAllAccounts(false);
      setDisplayFiltredAccounts(true);
    }
    else {
      setDisplayAllAccounts(true);
      setDisplayFiltredAccounts(false);
    }
  }, [searchedAccounts]);

  useDebounce(searchQuery, 500, searchForAccounts);

  return(
    <div className="module-wrapper">
      <div className="module-header">
        <div className="module-title">Gestion des comptes utilisateurs</div>
        <div className="modulte-tools">
          <ul>
            {!displayAccountListe && (
              <li>
                <div 
                  className="tool-btn" 
                  onClick={showAccountListe}
                ><MdHowToReg /><span>Gérer les comptes</span></div>
              </li>
            )}
            <li>
              <div 
                className="tool-btn" 
                onClick={showAccountCreate}
              ><MdAdd /><span>Créer un compte</span></div>
            </li>
            <li>
              <div 
                className="tool-btn" 
                onClick={showAccountImport}
              ><MdUploadFile /><span>Importer des comptes</span></div>
            </li>
          </ul>
        </div>
      </div>

      <div className="module-body">
        {displayAccountCreate && (
          <AccountCreate />
        )}
        {displayAccountEdit && (
          <AccountEdit
            currentAccount={currentAccount}
          />
        )}
        {displayAccountListe && (
          <div className="accounts-manager">
            <div className="accounts-searchbar">
              <input type="search"
                     name='user-search'
                     placeholder='Rechercher'
                     ref={inputRef}
                     value={searchQuery}
                     onChange={onSearchHandler}
                     onKeyDown={handleKeyPress}
              />
            </div>
            <ul className="accounts-lst-wrapper">
              <AccountLineHeader />
              {noAccounts && (
                <li>Aucun accomptes trouvés dans la base de données...</li>
              )}
              {!noAccounts && displayAllAccounts &&  (
                <>
                  {accounts.map((account, index) => (
                    <li className={`accounts-lst-account ${testIsClient(account.o_roles)}`} key={account.i_user + '-'  + index}>
                      <div className="account-name">{account.x_nom}</div>
                      <div className="account-firstname">{account.x_prenom}</div>
                      <div className="account-email">{account.x_email}</div>
                      <div className="account-organisation">{account.x_organisation}</div>
                      <div className="account-username">{account.x_username}</div>
                      <div className="account-roles">
                        <ul className="account-lst-roles">
                          {account.o_roles.map((role, index) => (
                            <li key={role.c_role + '-' + index}>{role.c_role}</li>
                          ))}
                        </ul>
                      </div>
                      <div className={`account-pwdchanged ${(account.b_pwd_changed === 1) ? 'green' : 'red'}`}>{(account.b_pwd_changed === 1) ? ( <MdDone title='Mot de passe modifié' /> ) : ( <MdClear title='Mot de passe non modifié' /> )}</div>
                      <div className="account-edit btn">
                        <MdCreate
                          onClick={() => showAccountEdit(account)}
                        />
                      </div>
                      <div className="account-delete btn">
                        <MdDeleteOutline 
                          data-userid={account.i_user}
                          onClick={userDelete} 
                        /></div>
                    </li>
                  ))}
                </>
              )}
              {!noAccounts && displayFiltredAccounts && (
                <>
                  {searchedAccounts.map((account, index) => (
                    <li className={`accounts-lst-account ${testIsClient(account.o_roles)}`} key={account.i_user + '-'  + index}>
                      <div className="account-name">{account.x_nom}</div>
                      <div className="account-firstname">{account.x_prenom}</div>
                      <div className="account-email">{account.x_email}</div>
                      <div className="account-organisation">{account.x_organisation}</div>
                      <div className="account-username">{account.x_username}</div>
                      <div className="account-roles">
                        <ul className="account-lst-roles">
                          {account.o_roles.map((role, index) => (
                            <li key={role.c_role + '-' + index}>{role.c_role}</li>
                          ))}
                        </ul>
                      </div>
                      <div className={`account-pwdchanged ${(account.b_pwd_changed === 1) ? 'green' : 'red'}`}>{(account.b_pwd_changed === 1) ? ( <MdDone title='Mot de passe modifié' /> ) : ( <MdClear title='Mot de passe non modifié' /> )}</div>
                      <div className="account-edit btn">
                        <MdCreate
                          onClick={() => showAccountEdit(account)}
                        />
                      </div>
                      <div className="account-delete btn">
                        <MdDeleteOutline 
                          data-userid={account.i_user}
                          onClick={userDelete} 
                        /></div>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        )}
        {displayAccountImport && (
          <AccountImport/>
        )}
      </div>
    </div>
  );
}

function AccountLineHeader() {
  return(
    <li className="accounts-lst-account titles">
      <div className="account-name">Nom</div>
      <div className="account-firstname">Prénom</div>
      <div className="account-email">Email</div>
      <div className="account-organisation">Organisation</div>
      <div className="account-username">Utilisateur</div>
      <div className="account-roles">Role</div>
      <div className="account-pwdchanged">&nbsp;</div>
      <div className="account-edit">&nbsp;</div>
      <div className="account-delete">&nbsp;</div>
    </li>
  );
}