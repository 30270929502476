import React from 'react';

import './Footer.scss';

export default function Footer(props) {
  return(
    <footer>
      &copy; Equinoxe MIS Development - 2023 - v. {props.version} 
    </footer>
  )
}