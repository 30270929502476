import React, { useState, useEffect } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { useCookies  } from 'react-cookie';

import { MdManageSearch, MdAccountCircle, MdEditNote } from "react-icons/md";

import './Mainmenu.scss';

export default function Mainmenu(props) {
  const [cookies] = useCookies(['isLoggedAdmin']);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if(cookies.isLoggedAdmin) {
      cookies.isLoggedAdmin.roles.some((role) => {
        if(role.c_role === 'ADMIN_DOC') {
          setIsAdmin(true);
          return true;
        }
      });
    }
  }, []);

  return(
    <nav>
      <ul>
        {props.logged && (
          <>
            <LiLink to="/writer" icone={<MdEditNote/>}>Ecrire un article</LiLink>
            {isAdmin && (
              <>
                <LiLink to="/crawler" icone={<MdManageSearch/>}>Crawler</LiLink>
                <LiLink to="/accounts" icone={<MdAccountCircle/>}>Création de compte</LiLink>
              </>
            )}
          </>
        )}
      </ul>
    </nav>
  );
}

function LiLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return(
    <li className={isActive ? "selected" : ""}>
      <Link  to={to} {...props} title={children}>
        {props.icone}
      </Link >
    </li>
  );
}