import React from 'react';
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { buildUrl, encodeJsonForApiRest } from '../tools/tool_urls';

export default function AccountEdit(props) {
  const formRef = useRef(null);
  const [currentUser, setCurrentUser] = useState([]);
  const [refreshCurrentUser, setRefreshCurrentUser] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [roles, setRoles] = useState([]);

  const queryOrganisations = buildUrl(['account', 'search', 'organisations']);
  const queryRoles = buildUrl(['account', 'search', 'roles']);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const base64String = encodeJsonForApiRest(currentUser);
    const editURL = buildUrl(['account', 'edit', base64String]);

    const sendData = async() => {
      await axios.post(editURL)
      .then((response) => {
        if(response.status === 200) {
          console.log('Utilisateur modifié...');
          // setCurrentUser([]);
          // formRef.current.reset();
        }
      })
      .catch((err) => {
        console.log('ERROR : ', err);
      });
    }

    sendData();
  }

  const onChangeHandler = (e) => {
    Object.keys(currentUser).forEach((key) => {
      if(key === e.target.name) {
        setCurrentUser({
          ...currentUser,
          [e.target.name]: e.target.value
        });
      }
    });    
  }

  const onRolesChangeHandler = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    console.log({currentUser});

    if(isRoleAssigned(e.target.value)) {
      console.log("Role existant, on le supprime...");
      currentUser.o_roles.map((role, index) => {
        // console.log({index});
        // console.log({role});
        if(role.c_role === e.target.value) {
          console.log("Supprimer l'index " + index);
          currentUser.o_roles.splice(index, 1);
        }
      });
    }
    else {
      console.log("Role inexistant, on l'ajoute...");
      currentUser.o_roles.push({'x_role': e.target.name, 'c_role': e.target.value});
    }
  }



  useEffect(() => {
    const fetchData = async() => {
      const response = await axios.get(queryOrganisations).catch((err) => {
        console.log('ERROR : ', err);
      });

      if(response) {
        setOrganisations(response.data);
      }
    }

    fetchData()
      .catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async() => {
      const response = await axios.get(queryRoles).catch((err) => {
        console.log('ERROR : ', err);
      });

      if(response) {
        setRoles(response.data);
      }
    }

    fetchData()
      .catch(console.error);
  }, []);

  useEffect(() => {
    setCurrentUser(props.currentAccount);
  }, [refreshCurrentUser]);

  const isRoleAssigned = (c_role) => {
    let result = false;

    props.currentAccount.o_roles.map((role) => {
      if(role.c_role === c_role) {
        result = true;
      }
    });

    return result;
  }

  return(
    <div className="account-create">
      <div className="title">Editer un compte</div>
      <form ref={formRef} onSubmit={onSubmitHandler}>
        <div className="form-container">
          <div className="field-container">
            <label>Nom</label>
            <div className="field">
              <input 
                type="text"
                name="x_nom"
                id="account-field-name"
                placeholder="Nom"
                value={currentUser.x_nom}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>Prénom</label>
            <div className="field">
              <input 
                type="text"
                name="x_prenom"
                id="account-field-firstname"
                placeholder="Prénom"
                value={currentUser.x_prenom}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>E-mail</label>
            <div className="field">
              <input 
                type="email" 
                name="x_email"
                id="account-field-email"
                placeholder="E-mail"
                value={currentUser.x_email}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>Organisation</label>
            <div className="field">
            <select 
              name="x_organisation" 
              id="account-field-organisation"
              value={currentUser.x_organisation}
              onChange={onChangeHandler}>
              <option value="null" defaultValue>{currentUser.x_organisation}</option>
              {organisations.map((organisation, index) => (
                <option key={organisation.c_organisation + '-' + index} value={organisation.c_organisation}>{organisation.x_organisation}</option>
              ))}
            </select>
            </div>
          </div>
          <div className="field-container">
            <label>Nom d'utilisateur</label>
            <div className="field">
              <input 
                type="text" 
                name="x_username"
                id="account-field-username"
                placeholder="Nom d'utilisateur"
                value={currentUser.x_username}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="field-container">
            <label>Rôle</label>
            <div className="field scrollable">
              <ul className="account-fields-roles">
                {roles.map((role, index) => (
                  <li key={role.c_role + '-' + index} className="field-container checkbox">
                    <div className="field">
                      <input
                        type="checkbox"
                        // name={"role-" + index}
                        name={role.x_role}
                        value={role.c_role}
                        checked={isRoleAssigned(role.c_role)}
                        onChange={onRolesChangeHandler}
                      />
                    </div>
                    <label>{'(' + role.c_role + ') ' + role.x_role}</label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="field-container span-2 content-right no-label">
            <label>&nbsp;</label>
            <div className="field">
              <input 
                type="submit" 
                value="Modifier"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}