import React from "react";

let generatorCharMaj = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
let generatorCharMin = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
let generatorCharNum = ['1','2','3','4','5','6','7','8','9','0'];
let generatorCharSpe = ['@','?','"','!','#','$',',','.','-','/',':',';','`','|','_','^','+','*','&','%','<','=','>','(',')','{','}','[',']','/\','/''];
let cryptoObject;

export const fieldPwdToTxt = (e) => {
  if(e.target.parentNode.dataset.field !== undefined) {
    let field = document.querySelector('#' + e.target.parentNode.dataset.field);
    if(field) {
      field.type = 'text';
    }
  }
}

export const fieldTxtToPwd = (e) => {
  if(e.target.parentNode.dataset.field !== undefined) {
    let field = document.querySelector('#' + e.target.parentNode.dataset.field);
    if(field) {
      field.type = 'password';
    }
  }
}

export const comparePassword = (pwdArray) => {
  let result = true;

  if(pwdArray.mdp1 !== pwdArray.mdp2) {
    result = false;
  }

  return result;
}

export const buildPassword = (size) => {
  let result = '';
  let characters = [];

  let charMaj = getCharacters(generatorCharMaj, 5);
  let charMin = getCharacters(generatorCharMin, 5);
  let charNum = getCharacters(generatorCharNum, 5);
  //let charSpe = getCharacters(generatorCharSpe, 5);

  Array.prototype.push.apply(characters, charMaj);
  Array.prototype.push.apply(characters, charMin);
  Array.prototype.push.apply(characters, charNum);
  //Array.prototype.push.apply(characters, charSpe);

  characters = shuffleArray(characters);

  // for(let i = 0; i < characters.length; i++) {
  for(let i = 0; i < size; i++) {
    result = result + characters[i];
  }

  return result;
}

const getCharacters = (src, nb) => {
  let result = [];

  for (let i = 0; i < nb; i++) {
    result.push(src[randomInt(src.length)]);
  }

  return result;
}

const randomInt = (n) => {
  let x = randomIntMathRandom(n);
  // x = (x + randomIntBrowserCrypto(n)) % n;
  return x;
}

const randomIntMathRandom = (n) => {
  let x = Math.floor(Math.random() * n);
  if (x < 0 || x >= n)
    throw "Arithmetic exception";
  return x;
}

const randomIntBrowserCrypto = (n) => {
  if (cryptoObject === null)
    return 0;
  // Generate an unbiased sample
  let x = new Uint32Array(1);
  do cryptoObject.getRandomValues(x);
  while (x[0] - x[0] % n > 4294967296 - n);
  return x[0] % n;
}

const shuffleArray = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}