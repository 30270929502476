import React from 'react';
import { useState, useRef } from "react";
import axios from "axios";
import { useCookies  } from 'react-cookie';

// import { MdDeleteOutline, MdDone, MdClear, MdCreate, MdAdd, MdHowToReg } from "react-icons/md";

import './Login.scss';

import { buildUrl, encodeJsonForApiRest } from '../tools/tool_urls';

export default function Login() {
  const formRef = useRef(null);

  // const [compLoginData, setCompLoginData] = useState(false);
  const [cookies, setCookies] = useCookies(['isLoggedAdmin']);
  const [userLogin, setUserLogin] = useState([]);
  const [loginError, setLoginError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const onChangeHandler = (e) => {
    setUserLogin({
      ...userLogin,
      [e.target.name]: e.target.value
    });
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if(userLogin.userlogin === undefined && userLogin.userpassword === undefined) {
      setLoginError(true);
      setErrorMsg("Saisissez votre nom d'utilisateur et votre mot de passe !");
    }

    if(userLogin.userlogin === undefined && userLogin.userpassword !== undefined) {
      setLoginError(true);
      setErrorMsg("Saisissez votre nom d'utilisateur !");
    }

    if(userLogin.userlogin !== undefined && userLogin.userpassword === undefined) {
      setLoginError(true);
      setErrorMsg("Saisissez votre mot de passe !");
    }

    if(userLogin.userlogin !== undefined && userLogin.userpassword !== undefined) {
      setLoginError(false);
      setErrorMsg('');

      const base64String = encodeJsonForApiRest(userLogin);
      const URL = buildUrl(['admin', 'login', base64String]);

      const sendData = async() => {
        await axios.post(URL)
        .then((response) => {
          if(response.status === 200) {
            if(!response.data.statut) {
              // Login incorrecte
              setLoginError(true);
              setErrorMsg("Erreur de saisie de votre nom d'utilisateur et/ou de votre mot de passe !");
            }

            if(response.data.statut) {
              // Login correcte
              setLoginError(false);
              setErrorMsg('');
              setUserLogin([]);
              let isAuthor = false;
              formRef.current.reset();

              // Test si rôle OK
              response.data.roles.some((role) => {
                if(role.c_role === 'ADMIN_DOC' || role.c_role === 'EQNX') {
                  isAuthor = true;
                  return true;
                }
              });

              // Données utilisateur
              const userData = {
                i_user: response.data.i_user,
                name: response.data.x_nom,
                firstname: response.data.x_prenom,
                email: response.data.x_email,
                organisation: response.data.x_organisation,
                roles: response.data.roles,
                autorised: isAuthor
              }
              
              if(!isAuthor) {
                // Role non OK
                setCookies('isLoggedAdmin', JSON.stringify(userData), { path: '/' });
                setLoginError(true);
                setErrorMsg("Vous n'êtes pas autorisé à vous connecter");
              }

              if(isAuthor) {
                // Role OK
                setCookies('isLoggedAdmin', JSON.stringify(userData), { path: '/' });
                setLoginError(false);
                window.location.reload();
              }
            }
          }
        })
        .catch((err) => {
          console.log('ERROR : ', err);
        });
      }

      sendData();
    }
  }

  return(
    <>
      <div className="main-wrapper login">
        <div className="form-wrapper">
          <h1>Login</h1>
          <form ref={formRef} onSubmit={onSubmitHandler}>
            <div className="line">
              <div className="message">Connectez-vous à l'application de gestion de la documentation</div>
            </div>
            {loginError && (
              <div className="msg">{errorMsg}</div>
            )}
            <div className="line">
              <label htmlFor="userlogin">Nom d'utilisateur</label>
              <input 
                type="text" 
                name="userlogin" 
                id="userlogin" 
                placeholder="Utilisateur"
                onChange={onChangeHandler}/>
            </div>
            <div className="line">
              <label htmlFor="userpassword">Mot de passe</label>
              <input 
                type="password" 
                name="userpassword" 
                id="userpassword" 
                placeholder="Mot de passe"
                onChange={onChangeHandler}/>
            </div>
            <div className="line">
              <input 
                type="submit" 
                name="Connexion" 
                value="Connexion"/>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}