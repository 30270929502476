import React from 'react';
import { useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { buildUrl, encodeJsonForApiRest } from '../tools/tool_urls';
import { fieldPwdToTxt, fieldTxtToPwd, comparePassword } from '../tools/tool_pwd';

import './ResetMpd.scss';

export default function ResetMdp() {
  const { token } = useParams();
  const formRef = useRef(null);

  const [newUserPwd, setNewUserPwd] = useState([]);
  const [newPwdEmpty, setNewPwdEmpty] = useState(true);
  const [newPwdOk, setNewPwdOk] = useState(false);

  const [isAlertMsg, setAlertMsg] = useState(false);
  const [msgType, setMsgType] = useState('');
  const [msgContent, setMsgContent] = useState('');

  const onChangeHandler = (e) => {
    setNewUserPwd({
      ...newUserPwd,
      [e.target.name]: e.target.value
    });
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();

    // Add token to obj
    newUserPwd.user_token = token;

    const base64String = encodeJsonForApiRest(newUserPwd);
    const URL = buildUrl(['account', 'resetmdp', base64String]);

    const sendData = async() => {
      await axios.post(URL)
      .then((response) => {
        console.log({response});
        if(response.status === 200) {
          if(!response.data) {
            setAlertMsg(true);
            setMsgType('error');
            setMsgContent('Token invalide. Merci de recommencer le processus...');
          }
          else {
            setAlertMsg(true);
            setMsgType('success');
            setMsgContent('Votre mot de passe a été modifié...');
            setNewUserPwd([]);
            setNewPwdEmpty(true);
            setNewPwdOk(false);
            formRef.current.reset();
          }
        }
      })
      .catch((err) => {
        console.log('ERROR : ', err);
      });
    }

    sendData();
  }

  const comparePwd = (e) => {
    setNewPwdOk(comparePassword(newUserPwd));
    if(newUserPwd.mdp2) {
      setNewPwdEmpty(false);
    }
  }

  return(
    <div class="main-wrapper">
      <div class="form-wrapper">
        <h1>Réinitialisation du mot de passe</h1>
        {isAlertMsg && (
          <div className={`msg ${msgType}`}>
            <span>{msgContent}</span>
          </div>
        )}
        <form ref={formRef} onSubmit={onSubmitHandler}>
          <div class="line">
            <div class="message">Saisier votre nouveau mot de passe</div>
          </div>
          <div class="line">
            <label for="mdp-1">Nouveau mot de passe</label>
            <input 
              type="password" 
              name="mdp1" 
              id="mdp1" 
              placeholder="Nouveau mot de passe"
              onChange={onChangeHandler}/>
            <div 
                className="icone"
                data-field='mdp1'
                onMouseDown={fieldPwdToTxt}
                onMouseUp={fieldTxtToPwd}>
              <MdOutlineRemoveRedEye/>
            </div>
          </div>
          <div class="line">
            <label for="mdp-2">Répétez le nouveau mot de passe</label>
            <input 
              type="password" 
              name="mdp2" 
              id="mdp2" 
              className={`neutral ${!newPwdEmpty ? (!newPwdOk ? 'error' : 'success') : ''}`}
              placeholder="Répétez le nouveau mot de passe"
              onChange={onChangeHandler}
              onKeyUp={comparePwd}/>
            <div 
                className="icone"
                data-field='mdp2'
                onMouseDown={fieldPwdToTxt}
                onMouseUp={fieldTxtToPwd}>
              <MdOutlineRemoveRedEye/>
            </div>
          </div>
          <div class="line">
            <input type="hidden" name="token" value={token}/>
            <input 
              type="submit" 
              name="Modifier" 
              value="Modifier"
              disabled={!newPwdOk}/>
          </div>
        </form>
      </div>
    </div>
  );
}