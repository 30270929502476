import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useCookies  } from 'react-cookie';

import './App.scss';

import Login from './components/comp_login/Login';

import Header from './components/layout_header/Header';
import Mainmenu from './components/layout_mainmenu/Mainmenu';
import Footer from './components/layout_footer/Footer';

import Writer from './components/comp_writer/Writer';
import Crawler from './components/comp_crawler/Crawler';
import Accounts from './components/comp_accounts/Accounts';
import ResetMdp from './components/comp_resetmdp/ResetMdp';

function App() {
  const [cookies] = useCookies(['isLoggedAdmin']);
  const [userLogged, setUserLogged] = useState(false);
  const [docToolsReactVersion] = useState('1.5.1 beta');

  useEffect(() => {
    if(cookies.isLoggedAdmin || cookies.isLoggedAdmin === 'true') {
      setUserLogged(cookies.isLoggedAdmin.autorised);
    }
  }, []);

  return (
    <div className="doc-tools-main">
      <Header/>
      {!userLogged ? (
        <>
          <Mainmenu logged={userLogged}/>
          <div className="doc-tools-container">
            <Routes>
              <Route path="/" element={ <Login/> } />
              <Route path="/resetmdp/:token" element={ <ResetMdp/> } />
              <Route path="*" element={ <Login/> } />
            </Routes>
          </div>
        </>
      ) : (
      <>
        <Mainmenu logged={userLogged}/>
        <div className="doc-tools-container">
          <Routes>
            <Route path="/" element={ <Writer/> } />
            <Route path="/writer" element={ <Writer/> } />
            <Route path="/crawler" element={ <Crawler/> } />
            <Route path="/accounts" element={ <Accounts/> } />
            <Route path="/resetmdp/:token" element={ <ResetMdp/> } />
          </Routes>
        </div>
      </>
      )}
      <Footer version={docToolsReactVersion}/>
    </div>
  );
}

export default App;
