import React from 'react';
import { useState, useEffect } from "react";
import { useCookies  } from 'react-cookie';
import { MdLogout } from "react-icons/md";

import './Header.scss';

import logo from './EQNX_Logo_blanc_S.png';

export default function Header() {
  const [cookies, setCookie, removeCookie] = useCookies(['isLoggedAdmin']);
  const [userLogged, setLoginData] = useState(false);

  useEffect(() => {
    if(cookies.isLoggedAdmin) {
      setLoginData(cookies.isLoggedAdmin.autorised);
    }
  }, []);

  const handleLogoutClick = () => {
    removeCookie('isLoggedAdmin', { path: '/' });
    window.location.reload();
  }

  return (
    <header>
      <div className="logo">
        <img src={logo} alt="Logo Equinoxe MIS Development"/>
      </div>
      <div className="main">
        {userLogged && (
          <h1>Bienvenue {cookies.isLoggedAdmin.firstname} {cookies.isLoggedAdmin.name}</h1>
        )}
      </div>
      <div className="tools">
      {userLogged && (
        <a href='.' className="btn logout" onClick={handleLogoutClick}>
          <MdLogout/>
        </a>
      )}
      </div>
    </header>
  );
}